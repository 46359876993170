<template>
    <div>
        <div v-show="!Object.keys(data).length" class="form-group form-inline">
            <label>Order</label>
            <input type="text" class="form-control" v-model="state.referenceID" />
            <button class="btn btn-default" @click="loadMetadata">Next</button>
        </div>

        <div v-show="Object.keys(data).length">
            Order: {{ data.tracker_id }}
            <br>
            Company: {{ data.company_name }}
            <br>
            PO: {{ data.distributor_po }}
            <br>
            Job Tag: {{ data.job_tag }}
            <br>
            <br>

            <strong>Attach To</strong>
            <br>
            <div class="form-group">
                <label>
                    <input type="radio" v-model="context" value="notebook" name="params[handler]" />
                    Notebook
                </label>
                <br>
                <label>
                    <input type="radio" v-model="context" value="tracker_curb" name="params[handler]" />
                    Item Drawing
                </label>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
    import { fileUploadStore } from "@/store/FileUpload.store";
    import appStore from "@/store/App.store";

    export default {
        name: "Tracker",
        data() {
            return {
                state: fileUploadStore.state,
                appState: appStore.state,
                context: null
            }
        },
        created() {
            fileUploadStore.initialize();
            fileUploadStore.state.allowAttach = false;
        },
        methods: {
            loadMetadata: function () {
                fileUploadStore.loadMetadata();
            }
        },
        computed: {
            data: function () {
                if (appStore.state.fileUploadContextData.metadata) {
                    return appStore.state.fileUploadContextData.metadata;
                }

                return appStore.state.fileUploadContextData.data;
            }
        },
        watch: {
            context: function () {
                this.$appStore.setFileUploadContext(this.context);
            }
        },
        mounted() {
            this.loadMetadata();
        }
    }
</script>

<style scoped>

</style>